/* Assuming you have a global CSS file */
.breadcrumb {
    list-style: none;
    padding: 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .bread {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-right: 5px;
  }
  
  .bread:last-child {
    font-weight: bold;
    color: #666;
  }
  
  .bread a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .bread a:hover {
    color: #007bff;
  }
  
  .bread::after {
     margin: 0 5px;
    color: #999;
  }
  