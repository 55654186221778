/* Comment section styles */
.comment-list {
    width: 100%;
  }
  

  .my_comment{
     margin-bottom:-24px;
  }
.my_comment::before{
    content: "click here to edit your comment";
    color:#6f6920;
    font-size: 10px;
}

  .comment {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .comment-content {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .comment-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .user-name {
    display: block;
    left: 10px;
    top: 0px;
    position: absolute;
     margin-right: 10px;
}
  
  .comment-date {
    font-size: 12px;
    color: #999;
    position: absolute;
    right: 10px;
    top: 1px;
  }
  
  .comment-actions {
    margin-top: 10px;
  }
  
  .reply_comment_btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
  }
  
  .reply-form {
    margin-top: 10px;
    position: relative;
    left: 100px;
  }
  
  .comment-reply {
    width: 100%;
    min-height: 50px;
    padding: 5px;
    font-size: 14px;
    background: #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .replies-container {
    margin-top: 10px;
    background-color: white;
  }
  
  .reply {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    display: flex;
    position: relative;
    left: 100px;
    background-color: #ccc;
    border-radius: 10px;
  }
  
  .reply-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .reply-content {
    font-size: 14px;
    line-height: 1.4;
  }
  
  /* Spinner styles */
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  
  /* Load more button styles */
  .load-more {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .load-more button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
.reply_avatar{
    position: absolute;
    left: 0px;
}

.delete-comment {
    position: absolute;
    right: 10px;
    top: 20px;
    visibility: hidden;
}

.comment:hover .delete-comment{
  visibility: visible;
}
