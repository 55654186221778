.board-card{
    max-height: 80px!important;
 }

.card-img-top {
    border-radius: 10px;
}

.card-img-top:hover{
    cursor: pointer;
}

.space_item{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    height: 50px;
}

.navigate_to_space_page{
    color: rgb(8, 15, 227);
    position: absolute;
    left: 20px;
    top: 40%;
}


.space_star{
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
}