/* Header breadcrumb */
.header_breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

/* Main page content */
.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Workspace list */
.workspace-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  text-align: center;
}

/* Workspace card */
.workspace-card {
  background-color: #e3f6f5;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  color: #333;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.workspace-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.workspace-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.workspace-card p {
  font-size: 14px;
  margin-bottom: 5px;
}

.workspace-type {
  display: inline-block;
  background-color: #4286f4;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Space list */
.space-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* Space card */
.space-card {
  background-color: #ff8f8f;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* No workspaces message */
.no-workspaces-message {
  font-size: 24px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #555;
}