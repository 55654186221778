.link_cards{
display: flex;
flex-wrap: wrap;
}

.link_card{
    border: 1px solid green;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    background-color: #ccc;
    min-width: 200px;
    /* height: 150px; */
}
.link_card:hover{
    background-color: rgb(22, 144, 205);
    color:white;
}
.btn_1{
    border:1px solid transparent;
    background-color: #cef;
    margin:5px;
    border-radius: 10px;
}

.btn_1:hover{
    background-color: #432;
    color:white;
}