 .custom-modal .modal-dialog {
    max-width: 800px;  
  }
  

  .workspaceImage{
    width: 350px;
  }

  .spaces_checkbox_container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    margin-left: 30px;
    /* background-color: #ccc; */
    border-radius: 10px;
  }
.checklist_item{
  display: flex;
}
.checklist_item > label{
  margin:2px;
  cursor: pointer;
  user-select: none;
}
  .space_checklist{
    margin-left: 7px;
  }