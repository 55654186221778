.user-card{
    position: relative;
}

.user-id{
    position: absolute;
    right: 10px;
    top: 10px;
    color: #123;
}

.user-img{
    position: absolute;
    left: 40%;
    top: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #123;
 }