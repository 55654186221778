.card_item{
    border: 1px solid#1c1b1b;
    padding: 1px;
    text-align: center;
    margin:4px 0px;
    border-radius: 8px;
    background-color: #fff;
 
}

.checklists{
    position: relative;

}


.checklists-completed-count{
    font-size: 12px;
}

.card-task-footer {
     display: flex;
    justify-content: space-between;
    align-items: center;
}