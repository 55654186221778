.addCard{
    padding: 0;
    background-color: #ccc;
}

.addCard > input{
    width:100%
}

.add-new-card{
    text-align: center;
    background-color: #ccc;
    border-radius: 10px;   
}