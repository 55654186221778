/* App.css */

.sidebar-toggle {
  position: fixed;
  bottom: 6%;
  left: 0;

  transform: translateY(-50%);
  z-index: 999;
}

.sidebar-toggle.sidebar-open {
  left: 180px; /* Adjust the width of the sidebar as needed */
}

.sidebar {
  margin-top: 40px;
  width: 180px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
  background-color: #fff;
  color: rgb(44, 62, 80);
}

.sidebar.open {
  left: 0;
}

.sidebar-section {
  padding: 10px;
}

.sidebar-section h5 {
  margin-bottom: 5px;
}

.sidebar-section ul {
  list-style: none;
  padding-left: 0;
}

 

.addBoardIcon:hover {
  color: rgb(12, 201, 222);
  cursor: pointer;
}

.sidebar-open {
  width: 50px;
}

.star:hover {
  color: rgb(12, 201, 222);
}

.sidebar-item {
  padding: 5px;
  font-size: 18px;
  display: block;
  color: rgb(44, 62, 80);
  border-radius: 10px;
}

.sidebar-item:hover {
  background-color: rgb(44, 62, 80);
  text-decoration: none;

}
.sidebar-item:hover .sidebar-link {
  color: #fff;
}
.sidebar-item:hover .icon{
  color: #fff;
}

.sidebar-item-container {
  position: relative;
}

.star {
  position: absolute;
  right: 10px;
  top: 30%;
}

/* updates  */

/* styles.css */

/* Add your global styles here */

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 2px;
  text-decoration: none;
  color: rgb(44, 62, 80);
  transition: background-color 0.2s, color 0.2s;
}

 

.sidebar-item .icon {
  margin-right: 10px;
}

.sub-menu {
  display: none;
  padding-left: 20px;
}

.sub-menu.active {
  display: block;
}

.sidebar_sub_link{
  color:rgb(44, 62, 80);
 width: 100%;
 display: block;
 padding-left: 10px;
}

.sidebar_sub_link:hover{
  background-color: rgb(44, 62, 80);
  color: white;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
}

.active_item{
  background-color: rgb(44, 62, 80);
  color: white;
  border-radius: 5px;
}

.sidebar-item{
   margin:10px
}
.sidebar-link{
  color:rgb(44, 62, 80);

}
.sidebar-link:hover{
  text-decoration: none;
  color: white;
}

.settings_item{
  font-size: 12px;
  position: absolute;
  bottom: 90px;
  width: 80%;
}