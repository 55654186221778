 

  .dropdown-menu.show {
    transform: translateX(-130px);
}

.avatar-img{
  width: 30px;
  border-radius: 50%;
}

.navbar {
  z-index: 999;
  background-color: #2C3E50 !important;
 height: 40px;
 
}

.navbar-light .navbar-nav .nav-link , .navbar-light .navbar-brand{
  color: #fff !important
}

 

.go-to-admin{
  color: #fff;
}

.go-to-admin:hover{
  color:#cfc
}


/* Add this CSS to your existing Header.css file */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Adjust the z-index as needed to ensure it appears above other content */
  background-color: white; /* Set the desired background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a better visual effect */
}

 body {
  padding-top: 56px;  
}
 