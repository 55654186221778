.attachment-item {
  font-size: 18px;
  border: 1px solid #ccf;
  margin: 2px;
  border-radius: 10px;
}
.attachment-item:hover {
  background-color: #ccc;
  border-radius: 10px;
}
