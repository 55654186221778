/* Add your global styling here */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal_ {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-body {
    margin-bottom: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-cancel-button,
  .modal-confirm-button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    cursor: pointer;
  }
  
  .modal-confirm-button {
    background-color: #dc3545;
    color: white;
  }
  