body {
  font-family: Arial, sans-serif;
}


label {
  display: block;
  margin-top: 10px;
}

.login-form,
.signup-form {
  max-width: 350px;
  margin: auto;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}



.nice-btn{
  border: none;
  color: white;
  padding: 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.nice-btn:hover {
  background-color: #45a049;
}

.btn-save {
background-color: #0f4582c2;
}

.editable .div-editable{
  font-size: 10px;
}

.my_workspaces{
  background-color: #45a049;
  border-radius: 5px;
  padding: 6px;
   color: white;
   font-size: 15px;
} 