.workspace-container .selected-workspace{
    display: flex;

    
 }

.spaces-container, .boards-container, .tasks-container{
    padding: 10px;
    border: 1px solid #edf;
 }

 .lablel1{
    background-color: #2C3E50;
    padding: 10px;
    color: white;
 }

 .select_all_tasks{
    color: rebeccapurple;
    font-weight: bolder;
 }

 .permission_type{
    }
 .permission_type_row{
    user-select: none;
   cursor: pointer;
}

.form-check-input{
margin-right: 5px;
}

.form-check-label{
   line-height: .2;
   padding: 2px;
  }

  .selected_permission{
   background-color: #0cd3d3;
   color: white;
   padding: 5px;
   border-radius: 5px;
   margin: 10px;
  }
  .on_this_level{
   background-color:#0cd3d3;
   color: white;
   padding: 10px;
   user-select: none;
   cursor: pointer;
  }

  label{
   user-select: none;
   cursor: pointer;
  }


.worksace_select_{
   display: flex;
   margin: 10px;
   border: 1px solid #2C3E50;
   padding: 5px;
}

.task_description{
   background-color: #31394224;
}

.selected-radio {
   background-color: #4caf50;  
   color: #fff; 
   padding: 5px;
   margin:5px;
 }

 
 button.clear-button {
   background-color: #f44336; 
   color: #fff; 
   border: none;  
   border-radius: 4px; 
   cursor: pointer;  
   transition: background-color 0.3s ease; 
   display: flex;
   align-items: center;
   justify-content: center;
 }
  button.clear-button:hover {
   background-color: #d32f2f;  
 }
 