.input-editable {
  width: 100%;
}

.div-editable:hover {
  cursor: pointer;
}

.btn-cancel {
  background-color: #db0816c2;
}

.div-editable {
  position: relative;
 }
.pencil_editable {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  visibility: hidden;
}

.pencil_editable:hover {
  cursor: pointer;
}

.editable:hover .pencil_editable{
  visibility: visible;
}

