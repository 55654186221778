/* Add these styles to your CSS file */

.widget {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  
  .widget p {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .widget span {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Additional styling for icons */
  .widget svg {
    fill: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .widget {
      padding: 10px;
    }
    
    .widget p {
      font-size: 12px;
    }
    
    .widget span {
      font-size: 20px;
    }
  }
  .widget :hover .widget-container{
    background-color: red;
    display: block;
  }