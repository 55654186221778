.card-details {
}

.left-side{
  padding-left: 40px;
}
.action-btn {
  width: 150px;
}

.due-date {
  border: 1px solid #ccf;
  border-radius: 5px;
  padding-left: 10px;
  color: #033b4c;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.modal-content {
  min-width: fit-content
}

.card-icon {
  color: #569d9d;
}

.progress {
  height: 10px;
  font-size: 9px;
}

.add-Comment {
  padding: 10px;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}

.add-Description{
  padding: 10px;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}
.add-Description::before{
  content:"add Description"
}

.comment {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
  max-width: 400px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin: 8px;
}

.comment::before {
  content: "";
  position: absolute;
  top: 1px;
  left: -18px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #f0f0f0 transparent transparent;
}

.sb-avatar.sb-avatar--text{
  position: absolute;
  left: -37px;
  top: -1px;
}

.add-Comment::before{
  content:"add comment"
}

/* fontSize: 16,
display: "inline-block",
padding: 5,
margin: 6,
borderRadius: 12, */

.label-item{
  position: relative;
  display: inline-block;
  padding: 5px;
  margin: 4px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
 }

.delete-label{
  position: absolute;
  right: -12px;
  top: -12px;
  color:rgb(64, 5, 5);
  /* font-weight: bolder; */
  visibility: hidden;
 }

.label-item:hover .delete-label{
  visibility: visible;
 }

 .delete-label:hover{
  color:red
 }


 .invite-users-modal {
  width: 200px;
}

.member-container{
  margin: 10px;
}


