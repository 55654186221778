/* ListView.css */

.list-view {
    display: flex;
    flex-direction: column;
    }
  
  .list {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    border-radius: 20px;
  }
  
  .list-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .card-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .card-table th,
  .card-table td {
     border-bottom: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  .card-table td{
    font-size: 12px;
  }
  .card-table th {
    background-color: white;
    color: black;
  }
  

  
  .card-table tr:hover {
    background-color: #ebe3e3;
  }
  
  .list_title_tbl{
    display: inline-block;
    padding: 10px;
    color:white;
    border-radius: 50%;
   }

   .list_title_fixed{
    padding: 5px;
    background-color: #ccc;
    border-radius: 10px;    
    margin-left: 5px;
   }

   .add_new_task{
    width: 400px;
    margin-top: 10px;
   }