/* Replace with your desired styles */
.sidebar {
    width: 200px;
    transition: width 0.3s ease-in-out;
  }
  
  .sidebar.open {
    width: 210px;
  }
  
  .full-width{
    margin-left: 220px
  }
  
  .main-content {
   }

  .main-content.expanded {
    /* Adjust the width for the main content when the sidebar is open */
  }
  
  .main-content.collapsed {
    /* Adjust the width for the main content when the sidebar is closed */
  }
  