.board-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.status-section {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  /* width: 90px; */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
 
}

.status-title {
  font-size: 12px;
  margin-bottom: 10px;
  background: #cdd;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.board-items {
  list-style: none;
  padding: 0;
}

.board-item:hover {
  background-color: #c23;
  color: white;
  text-decoration: none;
}

.status-title:hover {
  background-color: rgb(6, 135, 126);
  color: white;
}
.board-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  margin-right: 10px;
  color: white;
}

.board-name {
  font-size: 16px;
  font-weight: bold;
}

.board-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #977b7b;
  border-radius: 10px;
  margin-bottom: 5px;
  color: white;
  background-color: #66afe9;
  font-size: 18px;
 }

.change-status {
  font-size: 12px;
  position: absolute; 
  right: 10px;
  top: 10;
  border-radius: 10px;
}

/* select  */
/* Add these styles to your CSS file */
.status-select {
  appearance: none;
  /* background-color: rgb(6, 135, 126); */
  color:white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 12px;
  cursor: pointer;
  width: 20%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
}

.status-select:hover {
  border-color: #b2b2b2;
}

.status-select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 175, 233, 0.25);
}

.space-header{
  display: flex;
  justify-content: space-between;
  background-color: #66afe9;
  padding: 5px 10px;
  border-radius: 10px;
  color:white;
}

.space_edit_name{
  position: absolute;
  top: 10px;
  right:10px;
}

.space_edit_name:hover{
  color: #66afe9;
}

.go_to_this_board{
  height: 40px;
  width: 90px;
  background-color: #66afe9;
 
}

.go_to_this_board:hover{
  color:#e309d5;
  transform:rotate(180deg);
  cursor: pointer;
}