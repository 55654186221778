/* External CSS file - Notifications.css */

/* General styles */
.notifications-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.notifications-list {
  list-style: none;
  padding: 0;
}

/* Notification item styles */
.notification-item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.notification-text {
  flex: 1;
  margin-right: 10px;
}

 

/* Badges styles */
.user-badge, .event-badge, .task-badge ,.card-badge , .notification-timestamp  {
  padding: 3px 5px;
  border-radius: 5px;
  color: #fff;
}

.user-badge {
  background-color: #7490af;  
}

.event-badge {
  background-color: #73a57f;  
}
 
.task-badge {
  background-color: #967615; 
}

.card-badge {
  background-color: #2b9099bb;  
}

.notification-timestamp{
  background-color: #53cf10bb;
}