/* Styling for the event list */
.event-list {
    margin-top: 2px;
    padding: 2px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  .table td, .table th{
    padding: 0.20rem;
    font-size: 12px;
  }

  .long-cell {
    max-width: 150px; /* Set your desired fixed width */
    word-wrap: break-word; /* Wrap long words */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for truncated text */
  }
  