.List {
  background-color: #f1f2f4;
  border-radius: 8px;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  margin: 6px;
  height: fit-content;
  font-size: 14px;
}

.dragged-item {
  transform: skewX(3deg);
  background-color: #3498db;
  animation: glowing 2s infinite;
  border: none;
  color: rgb(192, 170, 170);
}

@keyframes glowing {
  0%,
  100% {
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.7),
      0 0 20px rgba(52, 152, 219, 0.5), 0 0 30px rgba(52, 152, 219, 0.3);
  }

  50% {
    box-shadow: 0 0 20px rgba(52, 152, 219, 0.7),
      0 0 30px rgba(52, 152, 219, 0.5), 0 0 40px rgba(52, 152, 219, 0.3);
  }
}
