.create-new-board{
    width: 20%;
    height: 75px;
    margin: 10px;

}

.pencil:hover{
    color:aqua;
}

.workspace_edit_name{
    font-size: 24px;
    background-color: #ccc;
    padding: 10px;
    border-radius: 10px;
  }
  .workspace_edit_name:hover{
    background-color: #451e1e;
    color: white;
    }

    .create_space{
       
        background-color: #928a8a;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 18px;
    }
  

    .col-sm-3.board-card.text-center.my-2.create_space:hover {
        cursor: pointer;
        background-color: #cef;
    }
    

    