/* Sidebar_sa.css */

.sidebar_sa {
  background-color: #2c3e50;
  color: #ffffff;
  width: 200px;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  transition: all 0.3s;
}

.sidebar_sa:hover {
  width: 250px;
}

.sidebar-col {
  padding: 0;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 0;
  padding: 12px 15px;
  transition: background-color 0.3s;
}

.sidebar-menu li:hover {
  background-color: #34495e;
}

.sidebar-menu a {
  color: inherit;
  text-decoration: none;
}

/* Add animations and styles for links */
.sidebar-menu a {
  display: block;
  padding: 10px;
  transition: background-color 0.3s;
}

.sidebar-menu a:hover {
  background-color: #2980b9;
  color: #ffffff;
}

/* Additional styles for active link */
.sidebar-menu a.active {
  background-color: #2980b9;
  color: #ffffff;
}

.go-back{
    position: absolute;
}
