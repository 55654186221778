.mini-nav{
    background-color: rgba(8, 112, 112, 0.681);
    height: 40px;
}

.board-container{
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    overflow-y: scroll;
 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .pos_div{
     background-color: #BFC9CB;;
    margin: 2px;
    /* border-radius: 10px; */
    height: fit-content;
  }

  .disable-text-selection {
    user-select: none;
  }



  /* toggle button to change view of board: list , kanban */
 
.toggle-component {
  text-align: center;
  margin: 5px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.toggle-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;  
  border-radius: 5px;
}

.toggle-button.active {
  background-color: #09ddd3; /* Change the background color for the active button */
}

/* Additional styling for your components */
.component {
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.component h2 {
  margin: 0;
}

