.board-card{
    max-height: 80px!important;
     border-radius: 10px;
    padding: 4px;
    text-align: center;
 }

.card-img-top {
    border-radius: 10px;
}
.card-img-top:hover{
    background-color: #1691a9!important
}
.card-img-top:hover{
    cursor: pointer;
}

.addCard{
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
}

.addCard > input{
     border-radius: 10px;
    margin: 4px;
}

.closeIcon:hover{
color:aqua
}


.add_list{
    border-radius:8px;
    padding:8px;
    width: 200px !important

}


.add-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.267);
    border-radius: 10px;
    margin-top: 10px;
    background-color: #BFC9CB;
     height: 40px;
    font-size: 14px;
}

.add_list >input{
    border-radius: 10px;
  
 }