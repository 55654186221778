.card-events-list{
    min-width: 600px;
    font-size: 12px;
}

.user-event{
    color: rgb(136, 13, 13);
}

.user-type{
    color: green;
    font-weight: bold;
}

.user-date{
    color: darkgoldenrod;
}